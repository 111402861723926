import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { MenuModule } from 'primeng/menu'
import { ButtonModule } from 'primeng/button'
import { MenubarModule } from 'primeng/menubar'
import { SidebarModule } from 'primeng/sidebar'
import { DividerModule } from 'primeng/divider'
import { DropdownModule } from 'primeng/dropdown'

import { AuthLayoutComponent } from './auth/auth.component'

@NgModule({
  imports: [RouterModule, MenuModule, ButtonModule, MenubarModule, SidebarModule, DividerModule, DropdownModule],
  declarations: [AuthLayoutComponent],
})
export class LayoutsModule {}
