<main class="flex justify-content-end">
  <div
    class="flex flex-column transition-width transition-linear transition-duration-100"
    [style.width]="sidebar ? 'calc(100% - 256px)' : '100%'"
  >
    <header class="flex align-items-center shadow-1">
      <p-button
        (onClick)="onSidebar()"
        icon="pi pi-align-left"
        styleClass="p-button-lg border-noround border-none exxon-bg-blue"
      ></p-button>
      <div class="flex flex-1 justify-content-end align-items-center">
        <div (click)="menu.toggle($event)" class="cursor-pointer pl-3 pr-5">
          <p class="font-bold text-color-secondary my-0">
            <i class="pi pi-user mr-2"></i>
            {{ user.firstName + ' ' + user.lastName }}
          </p>
        </div>
        <p-menu #menu [popup]="true" [model]="headerMenuItems" styleClass="exxon-bg-gray"></p-menu>
      </div>
    </header>
    <p-sidebar
      [(visible)]="sidebar"
      [showCloseIcon]="false"
      [dismissible]="false"
      [modal]="false"
      [transitionOptions]="'100ms linear'"
      styleClass="exxon-bg-gray"
    >
      <ng-template pTemplate="header">
        <img [routerLink]="['/']" class="cursor-pointer" width="80px" [src]="getLogo()" 
        [alt]="getLogoAlt()" />
        <b class="exxon-blue ml-3 text-lg">SUIVI DES STOCKS</b>
      </ng-template>
      <ng-template pTemplate="content">
        <p-menu [model]="sidebarMenuItems" styleClass="w-full border-none exxon-bg-gray"></p-menu>
      </ng-template>
      <ng-template pTemplate="footer">
        <p-menu [model]="sidebarFooterItems" styleClass="w-full border-none exxon-bg-gray"></p-menu>
      </ng-template>
    </p-sidebar>
    <section class="p-3 overflow-y-scroll" style="height: calc(100vh - 44px)">
      <router-outlet></router-outlet>
    </section>
  </div>
</main>
