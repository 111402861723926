import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AuthLayoutComponent } from '@src/layouts/desktop/auth/auth.component'
import { AuthGuard } from '@src/core/guards/auth.guard'
import { LayoutsModule } from '@src/layouts/desktop/layouts.module'
import { UserKeycloakRoles } from '@src/core/keycloak-role'

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'account',
    pathMatch: 'full',
  },
  {
    path: 'account',
    component: AuthLayoutComponent,
    data: { roles: [UserKeycloakRoles.STOCK_ADMIN, UserKeycloakRoles.STOCK_MANAGER, UserKeycloakRoles.STOCK_OPERATOR, UserKeycloakRoles.STOCK_TRANSPORTER] },
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/account.module').then((m) => m.AccountModule),
  },
  { path: '**', redirectTo: 'account' },
]

@NgModule({
  imports: [LayoutsModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutesModule { }
